var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Using native browser inputs"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeNativeInput)+" ")]},proxy:true}])},[_c('b-card-text',[_c('span',[_vm._v("The scope contains attributes and event handlers that can be directly bound to native ")]),_c('code',[_vm._v("<input>")]),_c('span',[_vm._v(" or ")]),_c('code',[_vm._v("<select>")]),_c('span',[_vm._v(" elements.")])]),_c('div',[_c('b-form-tags',{attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New tag - Press enter to add"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v(" Add ")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"shadow-none border mt-1 mr-1 mb-0",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(
                  /\s/g,
                  '_'
                )) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_vm._v(" remove ")])],1)}),1)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }